import { useContext, useState } from "react";
import HolidaysContext from "../contexts/holidays-context";
import { aggregateDates, processDates } from "../holidays/holidayFunctions";
import { useGeneric } from "../useGeneric";
import HolidayRequestView from "./HolidayRequestView";
import { compareDay, isSameDate } from "../functions";

export default function HolidayRequests() {
  const context = useContext(HolidaysContext);
  const [currentRequestStatus, setCurrentRequestStatus] = useState("blank");
  const [currentRequestID, setCurrentRequestID] = useState(undefined);
  const personData = context.translatorsRaw.find(
    (e) => e.id === context.user.id
  );
  const personName = personData["First Name"] + " " + personData["Last Name"];
  const [update, setUpdate] = useState(0);

  const myRequestsRaw = useGeneric({
    name: "requests",
    query: { person: personName, year: context.date.getFullYear() },
    update: update + context.date.getFullYear(),
    reload: 10,
  });

  const myRequests = !myRequestsRaw.loading ? myRequestsRaw.rows : undefined;

  console.log(myRequestsRaw);
  const userID = context.user.id;
  const employeeID = context.user.employeeID;
  const [selectedRange, setSelectedRange] = useState(false);

  const activeDatesPre = context.activeDates
    .filter(
      (d) =>
        d.employeeID === employeeID &&
        new Date(d.date).getFullYear() >= new Date().getFullYear()
    )
    .sort((a, b) => new Date(a.date) - new Date(b.date));
  let activeDates = [];
  activeDatesPre.forEach((element) => {
    if (
      !activeDates.map((i) => i.date).includes(element.date) &&
      element.db === "admin"
    ) {
      activeDates.push(element);
    }
  });

  activeDatesPre.forEach((element) => {
    if (
      !activeDates.map((i) => i.date).includes(element.date)
    ) {
      activeDates.push(element);
    }
  });

  activeDates = activeDates.sort((a,b) => new Date(a.date) - new Date(b.date));
  console.log(activeDates);
  const aggregatesInitial = aggregateDates(activeDates, ["type", "approved"]);

  const aggregateSplit = aggregatesInitial.map((a) => {
    const start = a[0];
    const end = a.at(-1);
    const single = start.date === end.date;
    const thisSet =
      myRequests &&
      myRequests.filter((d) =>
        isSameDate(new Date(d.dateset.split(",")[0]), new Date(start.date))
      );

    return a;
  });

  const aggregates = aggregateSplit.map((a) => {
    const start = a[0];
    const end = a.at(-1);
    const single = start.date === end.date;

    const rangeJSX = (
      <>
        {!single &&
        new Date(end.date).getMonth() === new Date(start.date).getMonth()
          ? new Date(start.date).toLocaleDateString("pl-PL", {
              day: "numeric",
            })
          : new Date(start.date).toLocaleDateString("pl-PL", {
              month: "long",
              day: "numeric",
              year: "numeric",
            })}
        {!single ? (
          <>
            {" "}
            -{" "}
            {new Date(a.at(-1).date).toLocaleDateString("pl-PL", {
              month: "long",
              day: "numeric",
              year: "numeric",
            })}
          </>
        ) : (
          <></>
        )}{" "}
        ({a.length === 1 ? <>{a.length} dzień</> : <>{a.length} dni</>})
      </>
    );
    const rangeString =
      (!single &&
      new Date(end.date).getMonth() === new Date(start.date).getMonth()
        ? new Date(start.date).toLocaleDateString("pl-PL", {
            day: "numeric",
          })
        : new Date(start.date).toLocaleDateString("pl-PL", {
            month: "long",
            day: "numeric",
            year: "numeric",
          })) +
      " " +
      (!single
        ? " - " +
          new Date(a.at(-1).date).toLocaleDateString("pl-PL", {
            month: "long",
            day: "numeric",
            year: "numeric",
          })
        : "") +
      " (" +
      (a.length === 1 ? a.length + " dzień" : a.length + " dni") +
      ")";
    return {
      rangeJSX: rangeJSX,
      rangeString: rangeString,
      dates: a,
      dateStrings: a.map((d) => d.date),
    };
  });
  //aggregates.reverse();
  const monthAggregates = {};
  aggregates.forEach((item, ind, arr) => {
    const firstDate = item.dates[0].date;
    const firstMonth = new Date(firstDate).getMonth();
    const prevFirstDate = arr[ind - 1] && arr[ind - 1].dates[0].date;

    if (Array.isArray(monthAggregates[firstMonth])) {
      monthAggregates[firstMonth].push(item);
    } else {
      monthAggregates[firstMonth] = [item];
    }
  });

  console.log(monthAggregates);
  return (
    <div className="holidayRequestsWrapper">
      <div className="holidayRequests">
        <h2>Wnioski urlopowe {context.date.getFullYear()}</h2>
        <div>
          {myRequests &&
            Object.keys(monthAggregates)
              //  .reverse()
              .map((month) => {
                const m = new Date();
                m.setMonth(month);
                //console.log(agg);
                return (
                  <div>
                    <div className="requestMonthHeader">
                      {new Date(m).toLocaleDateString("pl-PL", {
                        month: "long",
                      })}
                    </div>
                    {monthAggregates[month].map((a, ind) => {
                      const request = myRequests
                        ? myRequests
                            .filter(
                              (item) => item.dateset === a.dateStrings.join(",")
                            )
                            .at(-1)
                        : undefined;

                      return (
                        <>
                          <div
                            className={
                              "holidayGroupItem" +
                              (request ? " sent" : "") +
                              (request && request.approved === 1
                                ? " approved"
                                : "") +
                              (request && request.approved === 2
                                ? " rejected"
                                : "") +
                              (request &&
                              (!request.active || request.cancelled === 1)
                                ? " inactive"
                                : "") +
                              (selectedRange === month + "-" + ind
                                ? " selected"
                                : "")
                            }
                            onClick={() => {
                              if (selectedRange === month + "-" + ind) {
                                setSelectedRange(false);
                              } else {
                                setSelectedRange(month + "-" + ind);
                                setCurrentRequestStatus(
                                  request
                                    ? request.cancelled === 1
                                      ? "cancelled"
                                      : request.approved === 1
                                      ? "approved"
                                      : request.approved === 2
                                      ? "rejected"
                                      : request.sent
                                      ? "sent"
                                      : "blank"
                                    : "blank"
                                );

                                setCurrentRequestID(
                                  request ? request.id : undefined
                                );
                              }
                            }}
                          >
                            <div className="holidayDatesRange">
                              {a.rangeJSX}
                            </div>
                            <div className="holidayType">
                              {a.dates[0].typestring}
                            </div>
                            <div className="info">
                              {request ? (
                                <>
                                  <strong>
                                    {request && request.cancelled === 1 ? (
                                      <>wycofany</>
                                    ) : request && request.approved === 1 ? (
                                      <>✓ </>
                                    ) : request && request.approved === 2 ? (
                                      <>odrzucony</>
                                    ) : (
                                      <>wysłany </>
                                    )}
                                  </strong>

                                  {new Date(
                                    request && request.approved
                                      ? request.approvedDate
                                      : request.sent
                                  ).toLocaleString("pl-PL", {
                                    year: "numeric",
                                    month: "numeric",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "2-digit",
                                  })}
                                  {request &&
                                  request.approved &&
                                  request.approvedPerson ? (
                                    <span className="approvedPerson">
                                      {" "}
                                      ~ {request.approvedPerson}
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <>brak wniosku</>
                              )}
                            </div>
                          </div>
                          {selectedRange !== false &&
                          selectedRange === month + "-" + ind ? (
                            <HolidayRequestView
                              selectedItem={
                                selectedRange
                                  ? monthAggregates[
                                      selectedRange.split("-")[0]
                                    ][selectedRange.split("-")[1]]
                                  : false
                              }
                              personName={personName}
                              setUpdate={setUpdate}
                              setSelectedRange={setSelectedRange}
                              currentRequestStatus={currentRequestStatus}
                              currentRequestID={currentRequestID}
                              myRequests={myRequests}
                            />
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    })}
                  </div>
                );
              })}
        </div>
        <div style={{ display: "none", marginTop: "32px" }}>
          <h2>Wnioski</h2>
          {myRequests ? (
            myRequests.map((r) => (
              <div
                style={{
                  display: "flex",
                  fontSize: "14px",
                  padding: "4px 9px",
                  background: r.approved
                    ? "rgb(170,240, 170)"
                    : "rgb(240,240,240)",
                  margin: "5px 0",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  {new Date(r.dateset.split(",")[0]).toLocaleDateString(
                    "pl-PL",
                    { year: "numeric", month: "numeric", day: "numeric" }
                  )}
                  {r.dateset.split(",")[0] !== r.dateset.split(",").at(-1) ? (
                    <>
                      {" "}
                      -{" "}
                      {new Date(r.dateset.split(",").at(-1)).toLocaleDateString(
                        "pl-PL",
                        { year: "numeric", month: "numeric", day: "numeric" }
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div>{r.approved ? "✓" : "?"}</div>
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
