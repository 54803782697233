import React, { useRef, useContext } from "react";
import HolidaysContext from "../contexts/holidays-context";
import DayButton from "./DayButton";

function DatesGroup(props) {
  const { group, groupIndex, groupArray } = props.group;
  const { person, canControl } = props;
  const context = useContext(HolidaysContext);
  const {
    setHoliday,
    setChange,
    contextParams,
    setContextParams,
    dayOffTypes,
  } = context;
  const datesGr = useRef(null);
  const daysOff = group.content.filter((item) => item.off === false).length;
  const multipleData = group.content
    .filter((item) => {
      const dateObj = new Date(item.date);
      return !item.off;
    })
    .map((item) => ({
      date: item.dayISO,
      //status: 1,
      type: group.prevType,
      translatorID: person.id,
      employeeID: person.employeeID
    }));

  return (
    <div
      className={
        "datesGroup" +
        (group.active ? " activeGroup" : "") +
        (group.betweenActive ? " betweenActiveGroup" : "")
      }
      key={groupIndex}
    >
      {(group.active || group.betweenActive) && group.content.length > 1 ? (
        <div
          ref={datesGr}
          className={
            "datesGroupLabel" +
            (contextParams && contextParams.visible && contextParams.group ? " contextActive" : "")
          }
          onClick={() => {
            if (!canControl) return false;
            console.log(group);
            if (
              window.confirm(
                "Czy na pewno " +
                  (group.active ? "usunąć dni wolne?" : "połączyć dni wolne?")
              )
            ) {
              !group.active
                ? setHoliday("add", multipleData, true)
                : setHoliday("remove", multipleData, true);
              setChange((prev) => prev + 1);
            }
          }}
          onContextMenu={(e) => {
            if (!canControl) return false;
            if (!group.active) return false;

            e.preventDefault();
            setContextParams(() => ({
              person: person,
              left:
                e.target.getBoundingClientRect().left +
                e.target.getBoundingClientRect().width / 2,
              top:
                e.target.getBoundingClientRect().top +
                (e.target.getBoundingClientRect().height - 5) +
                50,
              visible: true,
              group: true,
              new: false,
              multipleData: multipleData,
              formattedDate:
                new Date(multipleData[0].date)
                  .toLocaleDateString()
                  .slice(0, 2) +
                "-" +
                new Date(
                  multipleData[multipleData.length - 1].date
                ).toLocaleDateString() +
                "(" +
                new Date(multipleData[0].date).toLocaleDateString("pl-PL", {
                  weekday: "short",
                }) +
                "-" +
                new Date(
                  multipleData[multipleData.length - 1].date
                ).toLocaleDateString("pl-PL", {
                  weekday: "short",
                }) +
                ")",
            }));
          }}
        >
          <div className="datesSum">
            {group.betweenActive ? (
              <>
                Połącz {groupArray[groupIndex - 1].content[0].dayNumber}-
                {groupArray[groupIndex + 1].content[0].dayNumber}
              </>
            ) : daysOff > 1 ? (
              <>
                <span className="daysOffNumber">{daysOff}</span>{" "}
                <span className="daysOffText">dni</span>
              </>
            ) : (
              <>
                <span className="daysOffNumber">{daysOff}</span>{" "}
                <span className="daysOffText">d.</span>
              </>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="datesGroupInternal">
        {group.content.map((item, index) => (
          <DayButton
            index={item.person}
            key={item.person + "" + index}
            active={item.active}
            type={item.type}
            prevType={
              groupArray[groupIndex - 1] && groupArray[groupIndex - 1].type
            }
            nextType={
              groupArray[groupIndex + 1] && groupArray[groupIndex + 1].type
            }
            day={item.dayISO}
            dayClass={item.dayClass}
            dayNumber={item.dayNumber}
            dayOffType={item.dayOffType}
            person={person}
            setChange={setChange}
            weekend={item.weekend}
            off={item.off}
            dayOffTypes={dayOffTypes}
            setContextParams={setContextParams}
            setHoliday={setHoliday}
            db={item.db}
            approved={item.approved}
            canControl={canControl}
          />
        ))}
      </div>
    </div>
  );
}

export default DatesGroup;
