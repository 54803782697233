import { useContext, useEffect, useState } from "react";
import { URL } from "../../global";
import { useGeneric } from "../../useGeneric";
import { isSameDay } from "../WorkTime";
import { minutesToTimeString } from "../WorkTimeView";
import dateToSQL, { dateTimeToSQL } from "./dateToSQL";
import NewItem from "./NewItem";
import Row, { durationToMinutes } from "./Row";
import DatePick from "./DatePick";
import Loading from "../Loading";
import prettyNumber from "../../tables/functions/prettyNumber";

export function isSameTime(date1, date2) {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  return d1.getHours() === d2.getHours() && d1.getMinutes() === d2.getMinutes();
}

export default function Timesheet({ user, defaultDate, locationState }) {
  // item being edited
  const [editedItem, setEditedItem] = useState(false);

  // the selected date
  const [selectedDate, setSelectedDate] = useState(
    defaultDate ? defaultDate : new Date()
  );

  const [dateInput, setDateInput] = useState(
    defaultDate ? defaultDate : new Date()
  );

  useEffect(() => {
    if (Date.parse(dateInput)) {
      setSelectedDate(dateInput);
    }
  }, [dateInput]);

  // show menu for creating new event
  const [showCreate, setShowCreate] = useState(
    locationState && locationState.new ? true : false
  );

  // number of updates so far
  const [update, setUpdate] = useState(0);
  const [updateAll, setUpdateAll] = useState(0);

  const [similar, setSimilar] = useState();

  const loadActiveDays = useGeneric({
    name: "employeeActiveDays",
    query: {
      employeeID: user.employeeID,
      month: selectedDate ? new Date(selectedDate).getMonth() + 1 : undefined,
      year: selectedDate ? new Date(selectedDate).getFullYear() : undefined,
    },
    reload: 60,
    noRefresh: true,
    update: updateAll,
  });

  const activeDays = !loadActiveDays.loading ? loadActiveDays.rows : undefined;

  // the employee's work starts and ends on the selected date
  const startAndEndofWork = useGeneric({
    name: "attendanceByDay",
    query: {
      date: selectedDate,
      employeeID: user.employeeID,
    },
    reload: 8,
    noRefresh: true,
    update: update,
    // updateAll: updateAll,
  });

  // timesheet data from today
  const timesheetData = useGeneric({
    name: "timesheet",
    query: {
      date: dateToSQL(selectedDate),
      employeeID: user.employeeID,
    },
    noRefresh: true,
    reload: 10,
    update: update,
    // updateAll: updateAll,
  });
  const ts = !timesheetData.loading ? timesheetData.rows : undefined;

  const lastEmpty =
    ts && ts.at(-1)?.action_type === 0
      ? { value: true, obj: ts.at(-1) }
      : { value: false, obj: undefined };

  const startEnd = !startAndEndofWork.loading
    ? startAndEndofWork.rows
    : undefined;

  const attendance = {
    start: startEnd?.find(
      (item) => item.typeshort === 0 || item.typeshort === 2
    )?.timestring,
    end: startEnd?.find((item) => item.typeshort === 1 || item.typeshort === 3)
      ?.timestring,
  };

  const attendanceComplex = {
    start: startEnd
      ?.filter((item) => item.typeshort === 0 || item.typeshort === 2)
      ?.map((i) => i?.timestring),
    end: startEnd
      ?.filter((item) => item.typeshort === 1 || item.typeshort === 3)
      ?.map((i) => i?.timestring),
  };

  const workStarted = attendance.start ? new Date(attendance.start) : undefined;
  const workEnded = attendance.end ? new Date(attendance.end) : undefined;

  const workStartedArr =
    attendanceComplex.start &&
    attendanceComplex.start
      .map((s) => {
        const d = new Date(s);
        d.setSeconds(0);
        return d;
      })
      .sort((a, b) => a - b);
  const workEndedArr =
    attendanceComplex.end &&
    attendanceComplex.end
      .map((s) => {
        const d = new Date(s);
        d.setSeconds(0);
        return d;
      })
      .sort((a, b) => a - b);

  const tsSplit = workStartedArr?.map((workSegment, index) => {
    console.log(workSegment);
    return ts?.filter(
      (e) =>
        new Date(e.start) >= new Date(workSegment) &&
        (workEndedArr[index]
          ? new Date(e.start) <= new Date(workEndedArr[index])
          : true)
    );
  });

  console.log(tsSplit);
  if (workStarted) {
    workStarted?.setSeconds(0);
  }
  if (workEnded) {
    workEnded?.setSeconds(0);
  }

  const chars = ts
    ? ts.filter((item) => item.unit === "zzs").map((item) => item.count)
    : undefined;
  const charsTrans =
    ts &&
    ts
      .filter((item) => item.action_type === 1 && item.unit === "zzs")
      .map((item) => item.count);
  const charsOther =
    ts &&
    ts
      .filter((item) => item.action_type !== 1 && item.unit === "zzs")
      .map((item) => item.count);
  const sumChars =
    charsTrans && charsTrans.length
      ? charsTrans.reduce((a, b) => parseFloat(a) + parseFloat(b))
      : "";
  const sumCharsOther =
    charsOther && charsOther.length
      ? charsOther.reduce((a, b) => parseFloat(a) + parseFloat(b))
      : "";
  const minutes = ts
    ? ts
        .filter((item) => item.unit !== "")
        .map((item) => (item.minutes ? item.minutes : undefined))
    : undefined;

  const sumMinutes =
    minutes && minutes.length
      ? minutes.reduce(
          (a, b) => (a ? parseFloat(a) : 0) + (b ? parseFloat(b) : 0)
        )
      : undefined;

  const sumMinutesString = sumMinutes ? minutesToTimeString(sumMinutes) : "";


  // reduce the times between ins and outs when multiple
  /*const reduceMinutes = c
    .sort((a, b) => new Date(a.timestring) - new Date(b.timestring))
    .map((item, index, array) =>
      index > 1 &&
      item.typeshort === 0 &&
      array[index - 1] &&
      array[index - 1].typeshort === 1
        ? new Date(item.timestring) - new Date(array[index - 1].timestring)
        : 0
    )
    .reduce((a, b) => a + b);*/

  async function updateEntryByID(id, params) {
    const response = await fetch(URL + "components/TS_update.php", {
      credentials: "include",
      method: "POST",
      body: JSON.stringify({
        id: id,
        ...params,
      }),
    });
    const result = await response.json();
    setUpdate((prev) => prev + 1);
  }

  useEffect(() => {
    console.log(selectedDate);
    setUpdate((prev) => prev + 1);
  }, [selectedDate]);

  let timeAcrossBreaks = {};

  return (
    <div
      className="newTS"
      onClick={(e) => {
        // closing NewItem form when clicked outside
        if (showCreate && e.target.className === "overlay") {
          setShowCreate(false);
        }
      }}
    >
      {activeDays ? (
        <div className="activeDays">
          {activeDays.map((item) => {
            const today = isSameDay(new Date(item.date), selectedDate);
            return (
              <div
                className={today ? "active" : ""}
                onClick={() => {
                  setSelectedDate(new Date(item.date));
                  setDateInput(new Date(item.date));
                }}
              >
                {new Date(item.date).toLocaleDateString("pl-PL", {
                  day: "numeric",
                })}
              </div>
            );
          })}
        </div>
      ) : (
        <></>
      )}
      <div className="TSbuttons">
        <button
          className="regularButton newEntry"
          style={!workStarted ? { visibility: "hidden" } : {}}
          onClick={() => setShowCreate((prev) => !prev)}
        >
          <>☀ Nowy wpis</>
        </button>

        <div>
          <DatePick
            date={selectedDate}
            set={setDateInput}
            update={setUpdateAll}
          />
        </div>
        <div className="TSsum">
          <div>{sumMinutesString}</div>
          {sumChars || sumCharsOther ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>T: {prettyNumber(sumChars)} zzs</div>
              <div style={{ fontSize: "9px", paddingLeft: "9px" }}>
                {prettyNumber(sumCharsOther)} zzs
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className="TSforms">
        {showCreate && workStarted ? (
          <div>
            <NewItem
              user={user}
              workStarted={workStarted}
              ts={ts}
              setUpdate={setUpdate}
              setShowCreate={setShowCreate}
              lastEmpty={lastEmpty}
              selectedDate={selectedDate}
              locationState={locationState}
            />
          </div>
        ) : (
          <></>
        )}
      </div>

      {workStartedArr &&
        workStartedArr.map((wS, index) => {
          const ts = tsSplit?.[index];
          return (
            <div className={"TSlist" + (showCreate ? " lastOnly" : "")}>
              {wS ? (
                <>
                  <div
                    className="TSrow"
                    key={wS}
                    style={{
                      gridTemplateColumns: "100px 1fr",
                      paddingTop: "0",
                      padding: "5px 0",
                      minHeight: "auto",
                      marginBottom: "0px",
                      //borderBottom: "greenyellow 2px solid",

                      color: "green",
                      borderRadius: "7px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                        marginRight: "8px",
                        justifyContent: "center",
                      }}
                    >
                      {new Date(wS).toLocaleTimeString("pl-PL", {
                        hour: "numeric",
                        minute: "numeric",
                      })}
                    </div>
                    <div>
                      <span className="arrow" style={{ scale: "1.2 1.2" }}>
                        ➜
                      </span>{" "}
                      początek pracy
                    </div>
                  </div>
                  <div>
                    {ts &&
                    ts.length &&
                    wS &&
                    ts[0].start &&
                    isSameTime(wS, ts[0].start) ? (
                      <></>
                    ) : ts && ts[0] ? (
                      <div className="warning">
                        Uwaga! Pierwsza czynność nie zaczyna się na początku
                        pracy.
                        <button
                          className="regularButton"
                          style={{
                            marginLeft: "12px",
                            height: "21px",
                            padding: "2px 7px",
                          }}
                          onClick={() => {
                            updateEntryByID(ts[0].id, {
                              startTime: dateTimeToSQL(new Date(wS)),
                            });
                          }}
                        >
                          Popraw
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              ) : (
                <div className={"no-start"} key={selectedDate.toISOString()}>
                  Nie odnotowano rozpoczęcia pracy <br />w dniu{" "}
                  {selectedDate.toLocaleDateString("pl-PL", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}{" "}
                  (
                  {selectedDate.toLocaleDateString("pl-PL", {
                    weekday: "long",
                  })}
                  )
                  <br />
                </div>
              )}

              {ts && startEnd ? (
                ts.map((item, index) => {
                  if (parseInt(item.last_count)) {
                    //console.log(item.id, item.last_count);
                    timeAcrossBreaks[item.first_id] = item.minutes;
                    //console.log(timeAcrossBreaks)
                  } else {
                    timeAcrossBreaks[item.first_id] = timeAcrossBreaks[
                      item.first_id
                    ]
                      ? timeAcrossBreaks[item.first_id] + item.minutes
                      : item.minutes;
                  }
                  //console.log(timeAcrossBreaks)

                  return (
                    <Row
                      item={item}
                      index={index}
                      similarity={{ value: similar, set: setSimilar }}
                      timeAcrossBreaks={timeAcrossBreaks?.[item.first_id]}
                      updateData={setUpdate}
                      user={user}
                      key={item.id}
                      showEdit={{ id: editedItem, set: setEditedItem }}
                      editProps={{
                        //user: user,
                        //orders: orders,
                        workStarted: wS,
                        ts: ts,
                        setUpdate: setUpdate,
                        setShowCreate: setShowCreate,
                        lastEmpty: lastEmpty,
                        selectedDate: selectedDate,
                        defaults: {
                          id: item.id,
                          order: {
                            number: item.order_string,
                            id: item.order_id,
                            client: item.customer_name,
                            from: item.language_from,
                            to: item.language_to,
                            mainLang: item.language_main,
                          },
                          action_type: {
                            id: item.action_type,
                            name: item.action_type_string,
                            short: item.action_type_short,
                            color: item.action_type_color,
                            category: item.action_type_category,
                          },
                          comment: item.comment,
                          language: item.language_main,
                          text_type: item.text_type,
                          document: item.document_name,
                          document_id: item.document_id,
                          unit: item.unit,
                          sworn: item.sworn,
                          start: item.start,
                          customView:
                            item.order_string === "" &&
                            item.action_type_category === "order",
                        },
                      }}
                    />
                  );
                })
              ) : (
                <Loading text="Pobieram dane..." />
              )}
              {workEndedArr && workEndedArr[index] ? (
                <div
                  className="TSrow"
                  key={workEndedArr[index]}
                  style={{
                    gridTemplateColumns: "100px 1fr",
                    paddingTop: "0",
                    padding: "5px 0",
                    minHeight: "auto",
                    marginBottom: "0px",
                    //borderTop: "orangered 2px solid", //#fdff81;
                    color: "orangered",
                    borderRadius: "7px",
                  }}
                >
                  <div
                    style={{
                      width: "95px",
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                      justifyContent: "center",
                    }}
                  >
                    {workEndedArr[index] ? (
                      new Date(workEndedArr[index]).toLocaleTimeString(
                        "pl-PL",
                        {
                          hour: "numeric",
                          minute: "numeric",
                        }
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                  <div>
                    <span className="arrow" style={{ scale: "-1.2 1.2" }}>
                      ➜
                    </span>{" "}
                    koniec pracy
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          );
        })}
      {showCreate || editedItem ? (
        <div className="overlay" onClick={() => setEditedItem(false)}></div>
      ) : (
        <></>
      )}
    </div>
  );
}
