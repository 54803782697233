import { useEffect, useState } from "react";
import SearchBox from "./SearchBox/SearchBox";
import OrderDocuments from "./OrderDocuments";
import TimeEdit from "./TimeEdit";
import { useGeneric } from "../../../useGeneric";
import OrderItem from "./SearchBox/ResultTypes/OrderItem";

export default function NewOrderItem({
  types,
  orderData,
  setOrderData,
  orderDataTS,
  setOrderDataTS,
  otherData,
  setOtherData,
  language,
  defaults,
}) {
  const actionType = orderDataTS.action_type;
  const textType = orderDataTS?.text_type;

  const textTypes = types && types[actionType] ? JSON.parse("[" + types[actionType].text_types + "]") : [];

  const findDefaultDocs = useGeneric({
    name: defaults ? "findDocuments" : undefined,
    query: { orderID: defaults?.order ? defaults.order.id : false },
  });

  const defaultDocs = !findDefaultDocs.loading ? findDefaultDocs.rows : [];
  const documents = orderData && orderData.documents ?
    (typeof orderData.documents === "string"
      ? JSON.parse("[" + orderData.documents + "]")
      : orderData.documents) : undefined;

  useEffect(() => {
    if (!findDefaultDocs.loading && defaults) {
      setOrderData((prev) => ({
        ...prev,
        document_id: defaults.document_id,
        documents: defaultDocs,
      }));
    }
  }, [findDefaultDocs.loading]);

  useEffect(() => {
    if (documents && documents[0]) {
      setOrderData((prev) => ({
        ...prev,
        document_id: documents[0].id,
        documents: documents,
      }));
      if (documents[0].type) {
        let aType = 1;
        let tType = 1;
        switch (documents[0].type) {
          case "tłumaczenie": aType = 1; break;
          case "korekta": aType = 2; break;
          case "redakcja": aType = 5; break;
        }
        switch (orderData?.symbol) {
          case "newseria": tType = 4; break;
        }

        setOrderDataTS(prev => ({ ...prev, action_type: aType, text_type: tType}))
      }
    }
   
  }, [orderData?.id]);

  return (
    <>
      <SearchBox
        db="searchOrders"
        species="order"
        selectedItem={orderData}
        setSelectedItem={setOrderData}
        queryDefaults={{
          searchString: "",
          language: language
        }}
        queryDefaultOrder={["finish_date", "ASC"]}
        updateOnChanged={language}
        resultComponent={(props) => <OrderItem {...props} item={props.item} />}
        selectedComponent={(props) => <OrderItem {...props} item={props.item} />}
        genericSearch={false}
      />
      {orderData && orderData.documents ? (
        <OrderDocuments
          documents={documents}
          selectedOrder={orderData}
          setSelectedOrder={setOrderData}
          types={types}
        />
      ) : (
        <></>
      )}
      {types && (
        <div className="TS-types">
          <div className="action-types">
            {types
              .filter((t) => t.category === "order")
              .map((t) => (
                <button
                  className={
                    "action-type" + (actionType === t.id ? " active" : "")
                  }
                  style={
                    actionType === t.id
                      ? {
                          boxShadow:
                            "0 -6px " +
                            t.color +
                            " inset, 0 0 6px rgba(0,0,0,.3)",
                        }
                      : {}
                  }
                  onClick={() => {
                    setOrderDataTS((prev) => ({ ...prev, action_type: t.id }));
                  }}
                >
                  {t.name}
                </button>
              ))}
          </div>
          <div className="text-types">
            {[1, 3].includes(actionType) &&
              textTypes.map((t) => (
                <button
                  className={"text-type" + (textType === t.id ? " active" : "")}
                  onClick={() => {
                    setOrderDataTS((prev) => ({ ...prev, text_type: t.id }));
                  }}
                >
                  {t.view}
                </button>
              ))}
          </div>
        </div>
      )}
    </>
  );
}
