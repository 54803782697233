export default function OrderItem({
  item,
  detail = true,
  setActive,
}: {
  item: any;
  detail?: boolean;
  setActive?: any;
}) {
  const documents =
    typeof item.documents === "string"
      ? JSON.parse("[" + item.documents + "]")
      : item.documents;

  let documentNames =
    documents && documents.length
      ? [...new Set(documents.map((d: any) => d.name))]
      : [];
  documentNames.length = 4;

  let langTo = "";
  switch (item.to) {
    case "Ang":
      langTo = "EN";
      break;
    case "Pol":
      langTo = "PL";
      break;
    case "Niem":
      langTo = "DE";
      break;
  }

  return (
    <div
      className={"order-item" + (!detail ? " short" : "")}
      onClick={() => (setActive ? setActive(true) : undefined)}
    >
      <div className="number">
        <div>{item?.number ? item?.number?.replace(/\//g, ".") : <>-</>}</div>
      </div>

      <div className="client">
        {item.client ? (
          item.client
        ) : (
          <span style={{ color: "grey" }}>bez zlecenia</span>
        )}
      </div>
      {detail ? (
        <div className="doc-names">
          {documentNames &&
            documentNames.map((doc: any) => (
              <div>
                <div>{doc}</div>
              </div>
            ))}
          {documents.length > 1 ? (
            <div style={{ background: "none", marginLeft: "5px" }}>
              {documents.length} dok.
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      <div className="language-pair">
        <div>▸</div>
        <div>{langTo}</div>
      </div>
    </div>
  );
}
