import React, { useState, useRef } from "react";


function DayButton(props) {
  //const weekend = (props.weekend === 0 || props.weekend === 6 || props.weekend === true) ? true : false;
  const {
    dayClass,
    day,
    index,
    active,
    person,
    setChange,
    off,
    groupIndex,
    type,
    prevType,
    dayOffTypes,
    setContextParams,
    setHoliday,
    db,
    approved,
    canControl
  } = props;
  
  const box = useRef(null);
  const thisType = dayOffTypes.find((t) => t.code === parseInt(type));

  return (
    <>
      <div
        className={
          dayClass +
          " dateButton" +
          (active ? " active" : "") +
          (active && thisType ? " " + thisType.className : "") +
          (db === "admin" && approved ? " ironclad" : "")
        }
        key={index}
        ref={box}
        title={day + " " + (active && thisType ? thisType.fullName : "")}
        data-day={day}
        onClick={() => {
          if (!canControl) return false;
          if (!off) {
            if (!active) {
              setHoliday("add", { person: person.id, employeeID: person.employeeID, day: day});
              setChange((prev) => (prev = prev + 1));
            } else if (db !== "admin" && !approved) {
              setHoliday("remove", { person: person.id, day: day});
              setChange((prev) => (prev = prev + 1));
            }
          }
        }}
        onContextMenu={(e) => {
          if (!canControl) return false;
          if (!off) {
            const top = e.target.getBoundingClientRect().top + (e.target.getBoundingClientRect().height- 5);
            e.preventDefault();
            setContextParams(() => ({
              person: person.id,
              day: day,
              left: e.target.getBoundingClientRect().left + (e.target.getBoundingClientRect().width / 2),
              top: window.innerHeight - top < 301 ? 300 : top,
              visible: true,
              group: false,
              new: active ? false : true,
              formattedDate: new Date(day).toLocaleDateString() + ", " +
              new Date(day).toLocaleDateString("pl-PL", {
                weekday: "long",
              })
            }));
          }
        }}
      >
        {active ? props.dayNumber : ""}
      </div>
    </>
  );
}

export default DayButton;
